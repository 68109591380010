import React, { useEffect, useState } from 'react';
import style from './blogs.module.css';
import Page from '../../Components/Page/page';
import Section from '../../Components/Section/section';
import SmallestTitle from '../../Components/SmallestTitle/smallesttitle';
import Title from '../../Components/Title/title';
import Button from '../../Components/Button/button';
import placeholder from '../../Components/Card/placeholder.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Card from '../../Components/Card/card';
import SecondaireButton from '../../Components/Button/secondairebutton';
import { firebase } from '../../firebase';
import ROUTES from '../../Routes/routes';


const Blogs = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
      const fetchBlogs = async () => {
        try {
            const db = firebase.firestore();
            const snapshot = await db.collection('blogs').get();
            const blogsData = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    title: data.title,
                    description: data.description,
                    slug: data.slug,
                    image: data.imageUrl || null, // Use placeholder if no image
                    createdAt: data.createdAt 
                        ? new Date(data.createdAt.seconds * 1000).toLocaleDateString('nl-NL') // Format date
                        : 'Unknown Date'
                };
            });
            setBlogs(blogsData);
        } catch (error) {
            console.error("Error fetching blogs:", error);
        }
    };    

        fetchBlogs();
    }, []);
    return (
      <Page>
          {/* <Swiper
            style={{
              '--swiper-pagination-color': '#0D1B2A',
            }}
            slidesPerView={1}
            spaceBetween={300}
            pagination={{ clickable: true }}
            mousewheel={true}
            modules={[Mousewheel, Pagination]}
            className={style.mySwiper}
          >

            <SwiperSlide className={style.swiperSlide}>
              <div className={style.swiperSlideText}>
                <SmallestTitle>Featured</SmallestTitle>
                <Title>Lorem ipsum dolor sit amet, consetetur</Title>
                <p>
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                  sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet 
                  clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem 
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt 
                  ut labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
                <SecondaireButton>Lees verder</SecondaireButton>
              </div>
              <img src={placeholder} alt='blogpost' className={style.swiperSlideImg}/>
            </SwiperSlide>

            <SwiperSlide className={style.swiperSlide}>
              <div className={style.swiperSlideText}>
                <SmallestTitle>Featured</SmallestTitle>
                <Title>Lorem ipsum dolor sit amet, consetetur</Title>
                <p>
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                  sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet 
                  clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem 
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt 
                  ut labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
                <SecondaireButton>Lees verder</SecondaireButton>
              </div>
              <img src={placeholder} alt='blogpost' className={style.swiperSlideImg}/>
            </SwiperSlide>
          </Swiper> */}

        <Section >
          <Title customClass={style.titleBlog}>Blog Posts</Title>
          <div className={style.blogCards}>

          {blogs.map(({ id, title, description, slug, image, createdAt }) => (
                <Card
                  key={id}
                  to={`${ROUTES.blog.to}${slug}`}
                  title={title}
                  children={description}
                  image={image}
                  date={createdAt}
                />
            ))}
          </div>
            
            {/* <Button customClass={style.marginButton}>Laad meer</Button> */}
        </Section>
      </Page>
    );
};

export default Blogs;