import React from 'react';
import style from './footer.module.css';
import ROUTES from '../../Routes/routes';
import { Link, useLocation } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa";

import logo from '../../Images/Main/groot.webp';


const Footer = () => {
  const location = useLocation();

  return (
    <footer>
      <div className={style.footerAlign}>
        <Link to={ROUTES.home}>
          <img src={logo} alt='logo' className={style.logo}/>
        </Link>

        <div className={style.footerMiddle}>
          <Link to={ROUTES.home} className={location.pathname === ROUTES.home ? style.activeNav : ''}>Home</Link>
          <Link to={ROUTES.aboutme} className={location.pathname === ROUTES.aboutme ? style.activeNav : ''}>Over mij</Link>
          <Link to={ROUTES.cases} className={location.pathname === ROUTES.cases ? style.activeNav : ''}>Cases</Link>
          <Link to={ROUTES.blogs} className={location.pathname === ROUTES.blogs ? style.activeNav : ''}>Blogs</Link>
          <Link to={ROUTES.contact} className={location.pathname === ROUTES.contact ? style.activeNav : ''}>Contact</Link>
        </div>
        <a href='https://www.linkedin.com/in/bastiensarah/' target="_blank" rel="noopener noreferrer"><FaLinkedin size={25} color='#668355'/></a>
      </div>
      <div className={style.bottomFooter}>
        <p className={style.copyright}>© 2024 Movim management bv <br/>BTW BE1018.580.766</p>
        <div className={style.bottomFooter_policy}>
          <Link to={ROUTES.privacypolicy}>Privacy Policy</Link>
          <Link to={ROUTES.termsConditions}>Algemene voorwaarden</Link>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
