import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firebase } from '../../../firebase';
import Page from '../../../Components/Page/page';
import Section from '../../../Components/Section/section';
import style from './detailBlog.module.css';

const DetailBlog = () => {
    const { slug } = useParams();
    const [blogDetail, setBlogDetail] = useState(null);

    useEffect(() => {
        const fetchBlogDetail = async () => {
            try {
                const db = firebase.firestore();
                const querySnapshot = await db.collection('blogs').where('slug', '==', slug).get();
                if (!querySnapshot.empty) {
                    setBlogDetail(querySnapshot.docs[0].data());
                } else {
                    console.error("No case found with this slug.");
                }
            } catch (error) {
                console.error("Error fetching case detail:", error);
            }
        };

        fetchBlogDetail();
    }, [slug]);

    if (!blogDetail) {
        return <Page><Section><p>Loading...</p></Section></Page>;
    }

    const { content } = blogDetail;

    return (
        <Page>
            <Section>
                <div 
                    className={style.container} 
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </Section>
        </Page>
    );
};

export default DetailBlog;
