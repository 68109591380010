import React, { useState} from 'react';
import style from './privacypolicy.module.css';
import Page from '../../Components/Page/page';
import Title from '../../Components/Title/title';
import Section from '../../Components/Section/section';
import { Link } from 'react-router-dom';
import ROUTES from '../../Routes/routes';
import { AnalyticsService } from '../../Services/AnalyticsService';

import SmallTitle from '../../Components/SmallTitle/smalltitle';

const PrivacyPolicy = () => {
  const [optedIn] = useState(AnalyticsService.isOptIn());
    return (
        <Page>
          <Section>
            <Title>Privacy</Title>
            <p>
              Movim management bv hecht belang aan uw privacy. Hoewel de meeste informatie op deze
              site beschikbaar is zonder dat er persoonlijke gegevens moeten worden verstrekt, is het
              mogelijk dat u om persoonlijke informatie gevraagd wordt. Movim management bv behandelt
              de persoonsgegevens die u invult met respect voor uw privacy. Het volgt hiervoor de
              Algemene Verordening Gegevensbescherming.
            </p>

            <SmallTitle customClass={style.paddingTop}>Waarvoor, met wie en voor hoelang?</SmallTitle>
            <p>
              Als u de gegevens verzendt, geeft u Movim management bv toestemming om ze te gebruiken
              voor informatieve doeleinden m.b.t. de werking van Movim management bv. We gebruiken de
              gegevens enkel binnen de eigen werking.
            </p>

            <div>
              Hieronder verstaan we: <br/>
              <ul>
                <li>informatie-deling m.b.t. specifieke en/of toekomstige activiteiten</li>
                <li>beantwoorden van de vragen die u aan Movim management bv heeft gesteld</li>
              </ul>
            </div>

            <SmallTitle customClass={style.paddingTop}>Uw rechten</SmallTitle>
            <p>
              U heeft altijd het recht om uw persoonsgegevens in te zien en om foute gegevens aan te
              passen. In sommige gevallen kunt u ook uw gegevens laten wissen. Wilt u zich beroepen
              op deze rechten? Dat kan via het contactformulier op <Link to={ROUTES.contact}>deze pagina</Link>. Vermoedt u dat iemand
              uw persoonsgegevens onrechtmatig gebruikt? Meld het dan via
              sarah@movim-management.be. U heeft ook het recht om klacht in te dienen bij de
              Gegevensbeschermingsautoriteit.
            </p>

            <SmallTitle customClass={style.paddingTop}>Toepasselijk recht en bevoegde rechtbanken</SmallTitle>
            <p>
              Het Belgisch recht is van toepassing op deze site. In geval van een geschil zijn enkel de
              rechtbanken van het gerechtelijk arrondissement Oost-Vlaanderen, afdeling Gent, bevoegd.
            </p>
          </Section>
          
          <Section>
                <div>
                    <Title>Cookie Policy</Title>
                    <p>Wij gebruiken cookies om website verkeer te analyseren. We delen informatie over uw gebruik van onze site met onze analytics partners, die deze kunnen combineren met andere informatie die u aan hen heeft verstrekt of die zij hebben verzameld door uw gebruik van hun diensten.</p>
                    
                    <SmallTitle customClass={style.paddingTop}>Noodzakelijke Cookies</SmallTitle>
                    <p>Noodzakelijke cookies helpen een website bruikbaarder te maken door basisfuncties zoals paginanavigatie en toegang tot beveiligde delen van de website mogelijk te maken. Zonder deze cookies kan de website niet goed functioneren.</p>
                    <ul>
                        <li>www.movim-management.be</li>
                        <li>google-analytics-opt-in</li>
                        <li>Slaat de cookie-toestemmingsstatus van de gebruiker op voor het huidige domein</li>
                        <li>Maximale opslagduur: 1 jaar, Type: HTTP-cookie</li>
                    </ul>
                    <SmallTitle customClass={style.paddingTop}>Marketing/Analytische cookies</SmallTitle>
                    <p>Worden gebruikt om te monitoren en analyseren hoe bezoekers omgaan met een website. 
                        Deze cookies bieden waardevolle inzichten in het gebruikersgedrag, zoals het aantal bezoekers, de tijd die op pagina’s wordt doorgebracht en verkeersbronnen.</p>
                    <ul>
                        <li>Google</li>
                        <li>_ga</li>
                        <li>Wordt gebruikt om gegevens over het apparaat en het gedrag van de bezoeker naar Google Analytics te sturen. Volgt de bezoeker op verschillende apparaten en marketingkanalen.</li>
                        <li>Maximale opslagduur: 2 jaar, Type: HTTP-cookie</li>
                    </ul>
                    <ul>
                        <li>Google</li>
                        <li>_ga_#</li>
                        <li>Slaat de cookie-toestemmingsstatus van de gebruiker op voor het huidige domein</li>
                        <li>Maximale opslagduur: 2 jaar, Type: HTTP-cookie</li>
                    </ul>
                    <SmallTitle customClass={style.paddingTop}>Huidige Status</SmallTitle>
                    <p>{ optedIn ? <>U accepteert cookies.</> : <>U accepteert geen cookies.</>}</p>
                    <div>Aanpassen: <button className={style.button} onClick={() => {
                        // toggle optIn/out
                        if(optedIn) {
                            AnalyticsService.disable();
                        } else {
                            AnalyticsService.enable();
                        }
                        window.location.reload();
                    }}>{ optedIn ? "Blokkeer cookies" : "Accepteer Cookies"}</button></div>
                    
                    
                </div>
            </Section>
        </Page>
    );
};

export default PrivacyPolicy;