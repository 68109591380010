import React, { useState} from 'react';
import style from './privacypolicy.module.css';
import Page from '../../Components/Page/page';
import Title from '../../Components/Title/title';
import Section from '../../Components/Section/section';

const TermsConditions = () => {
    return (
      <Page>
        <Section>
          <Title>Algemene voorwaarden</Title>
          <ol>
            <li>Deze algemene voorwaarden zijn van toepassing op zowel dienstenleveringen als de verkoop van goederen.</li>
            <li>Door het invullen van het bestelformulier of het geven van een opdracht wordt de klant geacht deze Algemene Voorwaarden te aanvaarden.</li>
            <li>Betaling van alle facturen dient contant te geschieden, tenzij anders overeengekomen. Bij geheel of gedeeltelijk uitblijven van betaling op de vervaldag, zal het openstaande bedrag automatisch en zonder voorafgaande ingebrekestelling worden verhoogd met een rente zoals bepaald in artikel 5 van de wet van 2 augustus 2002 betreffende de bestrijding van betalingsachterstand bij handelstransacties, en een schadevergoeding van 10% (met een minimum van 40 euro).</li>
            <li>In geval van geschillen zijn uitsluitend de bevoegde rechtbanken van het betreffende rechtsgebied bevoegd.</li>
            <li>Klachten dienen door de klant schriftelijk en per aangetekende post te worden ingediend binnen 8 dagen na de factuurdatum.</li>
            <li>Zolang geleverde goederen en diensten niet volledig zijn betaald, blijven zij eigendom van de leverancier. Niettemin gaan alle risico's met betrekking tot de verkochte goederen en diensten over op de koper vanaf het moment van levering.</li>
            <li> Abonnementen worden automatisch verlengd, tenzij het abonnement tijdig schriftelijk wordt opgezegd, uiterlijk één (1) maand voor het einde van de termijn.</li>
            <li>Indien de klant zijn verplichtingen, waaronder de betalingsverplichting, niet tijdig nakomt, behouden wij ons het recht voor om verdere leveringen en diensten stop te zetten en de overeenkomst automatisch als ontbonden te beschouwen, zonder dat daarvoor een ingebrekestelling vereist is.</li>
          </ol>
          
          <p>Movim management bv - BTW BE1018.580.766 - dit privacybeleid is geldig vanaf 10/01/2025</p>
        </Section>
      </Page>
  );
};

export default TermsConditions;